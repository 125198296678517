<template>
  <b-container fluid>
    <bo-page-title />
    <section id="home">
      <b-tabs>
        <b-tab title="Page Settings">
          <bo-card title="Slideshow">
            <div class="d-flex align-items-center" v-if="moduleRole('update')">
              <b-form-group class="mb-0 mr-3">
                <b-form-checkbox :checked="autoPlay.isSlide == 'Y' ? true : false" @change="changeAutoPlay(autoPlay.isSlide)">Enable Autoplay?</b-form-checkbox>
              </b-form-group>
              <b-form-group class="mb-0" label-cols="auto" content-cols-md="4" label="Autoplay Delay"
                label-for="autoplayDelay">
                <b-input-group append="S" size="sm">
                  <b-form-input type="text" v-model="autoPlay.delay" @keyup="inputNum"
                  @keydown="inputNum" @change="changeDelay" :disabled="autoPlay.isSlide == 'N' ? true : false" id="autoplayDelay" size="sm" min="0" />
                </b-input-group>
              </b-form-group>
            </div>
            <hr>
            <draggable v-if="moduleRole('editSlider')" tag="div" class="row gutter-2 draggable" v-model="sliderData" @start="startDrag" @end="endDrag" draggable=".slider-item">
              <b-col lg="3" md="4" class="slider-item" v-for="(slider, k) in sliderData" :key="k">
                <bo-card-img :title="slider.ms_title_id" :src="uploader(slider.ms_image, '/assets/images/no-slide.jpg')">
                  <template #info>
                    <b-badge :variant="slider.ms_is_active == 'Y' ? 'success': 'warning'">{{slider.ms_is_active == 'Y' ? 'Active': 'Inactive'}}</b-badge>
                  </template>
                  <template #buttons>
                    <b-button
                      variant="secondary"
                      size="sm"
                      pill
                      @click="updateSlide(k)"
                      class="btn-icon"
                      triggers="hover"
                      v-b-tooltip.hover="'Update'"
                      v-if="moduleRole('editSlider')"
                    >
                      <i class="fas fa-pencil-alt" />
                    </b-button>
                    <b-button
                      class="btn-icon"
                      variant="info"
                      size="sm"
                      pill
                      @click="doChangeStatus(k,slider)"
                      v-if="moduleRole('editSlider')"
                      v-b-tooltip.hover="'Update Status'"
                    >
                        <i class="fas fa-cog"></i>
                    </b-button>
                    <b-button
                      @click="doDelete(k,slider,{obj: 'Slider'})"
                      variant="danger"
                      size="sm"
                      pill
                      class="btn-icon"
                      v-b-tooltip.hover="'Delete'"
                      v-if="sliderData.length > 1 && moduleRole('deleteSlider')"
                    >
                      <i class="far fa-trash-alt" />
                    </b-button>
                  </template>
                </bo-card-img>
              </b-col>
              <b-col lg="3" md="4" v-if="moduleRole('addSlider')">
                <a href="javascript:;" class="card-img-adder" @click="addSlide">
                  <i class="fas fa-plus"></i>
                  <span>Add Slider</span>
                </a>
              </b-col>
            </draggable>

            <div class="row gutter-2" v-else>
              <b-col lg="3" md="4" class="slider-item" v-for="(slider, k) in sliderData" :key="k">
                <bo-card-img :title="slider.ms_title_id" :src="uploader(slider.ms_image, '/assets/images/no-slide.jpg')">
                  <template #buttons>
                    <b-button
                      variant="secondary"
                      size="sm"
                      pill
                      @click="updateSlide(k)"
                      :disabled="slideId == k"
                      class="btn-icon"
                      v-b-tooltip.hover="'Update'"
                      v-if="moduleRole('editSlider')"
                    >
                      <i class="fas fa-pencil-alt" />
                    </b-button>
                    <b-button
                      @click="doDelete(k,slider)"
                      variant="danger"
                      size="sm"
                      pill
                      class="btn-icon"
                      v-b-tooltip.hover="'Delete'"
                      v-if="moduleRole('deleteSlider')"
                    >
                      <i class="far fa-trash-alt" />
                    </b-button>
                  </template>
                </bo-card-img>
              </b-col>
              <b-col lg="3" md="4" v-if="moduleRole('addSlider')">
                <a href="javascript:;" class="card-img-adder" @click="addSlide">
                  <i class="fas fa-plus"></i>
                  <span>Add Slider</span>
                </a>
              </b-col>
            </div>

            <b-collapse class="card-collapse" v-model="sliderCollapse">
              <b-card no-body class="border mb-0">
                <b-card-header>
                  <b-card-title title-tag="h5">Slide Editor</b-card-title>
                </b-card-header>
                <validation-observer
                  v-slot="{ handleSubmit }" ref="VForm"
                >
                <b-form @submit.prevent="handleSubmit(submitSlider(slideId))">
                  <b-card-body>
                    <b-row>
                      <b-col md="4">
                        <div class="form-group">
                        <label class="control-label">Hero Image <span class="text-danger">*</span></label>         <Uploader v-model="slideRow.ms_image" type="slider"/>
                        <VValidate 
                          name="Silder Image Desktop" 
                          v-model="slideRow.ms_image"
                          :rules="mrValidation.ms_image" 
                        />
                        </div>
                        <div class="form-group">
                        <label class="control-label">Hero Image Mobile <span class="text-danger">*</span></label>     <Uploader v-model="slideRow.ms_image_mobile" use-preview type="slider-mobile"/>
                        <VValidate 
                          name="Silder Image Mobile" 
                          v-model="slideRow.ms_image_mobile"
                          :rules="'required'" 
                        />
                        </div>
                        <div class="form-group">
                          <label class="control-label">Alt Image (ID)<span class="text-danger">*</span></label>
                          <input type="text" class="form-control" placeholder="Alt Image" v-model="slideRow.ms_image_alt_id" @keyup="removeWildChar" @keydown="removeWildChar">
                          <VValidate 
                            name="Alt Silder Image ID" 
                            v-model="slideRow.ms_image_alt_id"
                            :rules="{...mrValidation.ms_image_alt_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                          />
                        </div>
                         <div class="form-group">
                          <label class="control-label">Alt Image Mobile (ID)<span class="text-danger">*</span></label>
                          <input type="text" class="form-control" placeholder="Alt Image" v-model="slideRow.ms_image_alt_mobile_id" @keyup="removeWildChar" @keydown="removeWildChar">
                          <VValidate 
                            name="Alt Silder Image Mobile ID" 
                            v-model="slideRow.ms_image_alt_mobile_id"
                            :rules="{...mrValidation.ms_image_alt_mobile_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                          />
                        </div>
                        <div class="form-group">
                          <label class="control-label">Alt Image (EN)<span class="text-danger">*</span></label>
                          <input type="text" class="form-control" placeholder="Alt Image" v-model="slideRow.ms_image_alt_en" @keyup="removeWildChar" @keydown="removeWildChar">
                          <VValidate 
                            name="Alt Silder Image EN" 
                            v-model="slideRow.ms_image_alt_en"
                            :rules="{...mrValidation.ms_image_alt_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                          />
                        </div>
                        <div class="form-group">
                          <label class="control-label">Alt Image Mobile (EN)<span class="text-danger">*</span></label>
                          <input type="text" class="form-control" placeholder="Alt Image" v-model="slideRow.ms_image_alt_mobile_en" @keyup="removeWildChar" @keydown="removeWildChar">
                          <VValidate 
                            name="Alt Silder Image Mobile EN" 
                            v-model="slideRow.ms_image_alt_mobile_en"
                            :rules="{...mrValidation.ms_image_alt_mobile_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                          />
                        </div>
                        <div class="form-group">
                          <div class="form-group">
                            <label class="control-label">
                              Status
                              <span class="text-danger mr5">*</span>
                            </label>
                            <div class="d-flex">
                              <div class="custom-control custom-radio" style="margin-right: 10px;">
                                <input type="radio" name="radioRow" id="Yes" v-model="slideRow.ms_is_active" value="Y" class="custom-control-input" />
                                <label for="Yes" class="custom-control-label">Active</label>
                              </div>
                              <div class="custom-control custom-radio">
                                <input type="radio" name="radioRow" id="No" v-model="slideRow.ms_is_active" value="N" class="custom-control-input" />
                                <label for="No" class="custom-control-label">Inactive</label>
                              </div>
                            </div>
                            <VValidate 
                              name="Status" 
                              v-model="slideRow.ms_is_active"
                              :rules="mrValidation.ms_is_active" 
                            />
                          </div>
                        </div>
                      </b-col>
                      <b-col md="8">
                        <b-row no-gutters>
                          <div class="col-md-6">
                            <b-card class="border mb-0">
                              <template #header>
                                <h5 class="card-title">Content (ID)</h5>
                              </template>
                              <div class="form-group">
                                <label class="control-label">Title (ID) <span class="text-danger">*</span></label>
                                <input type="text" @keyup="removeWildChar" @keydown="removeWildChar" class="form-control" placeholder="Title"
                                  v-model="slideRow.ms_title_id" @input="autoFill('ms_image_alt_id','ms_image_alt_mobile_id','ms_title_id')">
                                <VValidate 
                                    name="Title ID" 
                                    v-model="slideRow.ms_title_id"
                                    :rules="{...mrValidation.ms_title_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                                  />
                              </div>
                              <div class="form-group mb-0">
                                <label class="control-label">Description (ID) <span class="text-danger">*</span></label>
                                <textarea class="form-control" rows="5" placeholder="Description"
                                  v-model="slideRow.ms_description_id" @keyup="removeWildChar" @keydown="removeWildChar"></textarea>
                                <VValidate 
                                  name="Description ID" 
                                  v-model="slideRow.ms_description_id"
                                  :rules="{...mrValidation.ms_description_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                                />
                              </div>
                            </b-card>
                          </div>
                          <div class="col-md-6">
                            <b-card class="border border-left-0 mb-0">
                              <template #header>
                                <h5 class="card-title">Content (EN)</h5>
                              </template>
                              <div class="form-group">
                                <label class="control-label">Title (EN)<span class="text-danger">*</span></label>
                                <input type="text" class="form-control" placeholder="Title"
                                  v-model="slideRow.ms_title_en" @input="autoFill('ms_image_alt_en','ms_image_alt_mobile_en','ms_title_en')" @keyup="removeWildChar" @keydown="removeWildChar">
                                <VValidate 
                                  name="Title EN" 
                                  v-model="slideRow.ms_title_en"
                                  :rules="{...mrValidation.ms_title_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                                />
                              </div>
                              <div class="form-group mb-0">
                                <label class="control-label">Description (EN) <span class="text-danger">*</span></label>
                                <textarea class="form-control" rows="5" placeholder="Description"
                                  v-model="slideRow.ms_description_en" @keyup="removeWildChar" @keydown="removeWildChar"></textarea>
                                <VValidate 
                                  name="Description EN" 
                                  v-model="slideRow.ms_description_en"
                                  :rules="{...mrValidation.ms_description_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                                />
                              </div>
                            </b-card>
                          </div>

                          <div class="col-12">
                            <b-card class="border border-top-0 my-0">
                              <div class="row align-items-center">
                                <div class="col-md-auto">
                                  <div class="form-group mb-0">
                                    <div class="custom-control custom-control-inline custom-checkbox">
                                      <input 
                                        type="checkbox" name="showBtn" 
                                        id="showBtn" class="custom-control-input" 
                                        :checked="slideRow.ms_button == 'Y' ? true : false" @click="isHaveButton(slideRow.ms_button)">
                                      <label for="showBtn" class="custom-control-label">Has Button?</label>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md" v-if="slideRow.ms_button == 'Y'">
                                  <div class="form-group mb-0 form-row align-items-center">
                                    <div class="col-md-auto">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </b-card>
                          </div>
                          <template v-if="slideRow.ms_button == 'Y'">
                            <div class="col-md-6">
                              <b-card class="border border-top-0">
                                <div class="form-group mb-2">
                                  <label class="control-label">Button Label (ID) <span class="text-danger">*</span></label>
                                    <input 
                                    type="text" 
                                    class="form-control" placeholder="Button Label"
                                    v-model="slideRow.ms_button_label_id"
                                  >
                                  <VValidate 
                                  name="Button Label ID" 
                                  v-model="slideRow.ms_button_label_id"
                                  :rules="{required: slideRow.ms_button == 'Y', regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                                />
                                </div>
                                <div class="form-group mb-0">
                                  <label class="control-label">Button Link (ID)</label>
                                  <input 
                                    type="text" 
                                    class="form-control" placeholder="e.g https://www.mtwi.co.id/produk"
                                    v-model="slideRow.ms_button_link_id"
                                  >    
                                  <VValidate 
                                    name="Link ID" 
                                    v-model="slideRow.ms_button_link_id"
                                    :rules="{regex:/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/}"
                                  />
                                </div>
                              </b-card>
                            </div>
                            <div class="col-md-6">
                              <b-card class="border border-top-0">
                                <div class="form-group mb-2">
                                  <label class="control-label">Button Label (EN) <span class="text-danger">*</span></label>
                                    <input 
                                    type="text" 
                                    class="form-control" placeholder="Button Label"
                                    v-model="slideRow.ms_button_label_en"
                                  >
                                   <VValidate 
                                    name="Button Label EN" 
                                    v-model="slideRow.ms_button_label_en"
                                    :rules="{required: slideRow.ms_button == 'Y', regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                                  />
                                </div>
                                <div class="form-group mb-0">
                                  <label class="control-label">Button Link (EN)</label>
                                  <input 
                                    type="text" 
                                    class="form-control" placeholder="e.g https://www.mtwi.co.id/produk"
                                    v-model="slideRow.ms_button_link_en"
                                  >    
                                  <VValidate 
                                    name="Link EN" 
                                    v-model="slideRow.ms_button_link_en"
                                    :rules="{regex:/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/}"
                                  />
                                </div>
                              </b-card>
                            </div>
                          </template>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-card-body>
                  <b-card-footer>
                    <div class="text-right">
                      <b-button @click="cancelAddUpdate(cancelSlider)" class="btn-rounded" variant="outline-secondary">
                        Cancel</b-button>
                      <b-button type="submit" class="btn-rounded ml-2" variant="primary">Save Changes</b-button>
                    </div>
                  </b-card-footer>
                </b-form>
                </validation-observer>
              </b-card>
            </b-collapse>
          </bo-card>
          <b-card>
            <template #header>
              <h5 class="card-title">Company Brief Description</h5>
            </template>
            <div class="d-flex align-items-center" ref="companyBriefForm">              
              <b-form-checkbox v-if="moduleRole('update')" id="company-brief" name="checkbox" @change="changeSection(showCompanyBrief,'switch-home-company-brief')" :checked="showCompanyBrief == 'Y' ? true : false"
              >Show Section?</b-form-checkbox>
              <b-button
                variant="secondary"
                class="btn-rounded ml-auto"
                v-if="companyBriefOpen"
                @click="closeFormCompany"
              >
                Cancel <i class="fas fa-times ml-2"></i>
              </b-button>
              <b-button
                variant="outline-info"
                class="btn-rounded ml-auto"
                @click="showUpdateCompanyBrief"
                v-else
              >
                Update Content <i class="fas fa-pencil-alt ml-2"></i>
              </b-button>

            </div>
            <hr />

            
            <validation-observer
              v-slot="{ handleSubmit }"
              ref="VFormContent"
              tag="div"
              v-if="companyBriefOpen"
            >
              <b-form @submit.prevent="handleSubmit(submitCompanyBrief)">
                <b-card class="border mb-0">
                  <template #header>
                    <h5 class="card-title">Content Editor</h5>
                  </template>
                  <div class="row">
                    <div class="col-md-9">
                      <div class="form-group mb-2">                      
                        <label>Content (ID) <strong class="text-danger">*</strong></label>
                        <CKEditor 
                          id="CompanyBrief_id"
                          ref="inputBriefCompany"
                          :ready-on-focus="true"
                          :customToolbar="configCKEditor"
                          :value.sync="companyBrief.ssc_desc_id"

                        />
                        <VValidate 
                          name="Description Id" 
                          v-model="companyBrief.ssc_desc_id"
                          :rules="companyBriefValidation.ssc_desc_id" 
                        />
                      </div>
                      <div class="form-group mb-2">                      
                        <label>Content (EN) <strong class="text-danger">*</strong></label>
                        <CKEditor 
                          id="CompanyBrief_en"
                          :value.sync="companyBrief.ssc_desc_en"
                          :customToolbar="configCKEditor"                        
                        />
                        <VValidate 
                          name="Description En" 
                          v-model="companyBrief.ssc_desc_en"
                          :rules="companyBriefValidation.ssc_desc_en" 
                        />
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <Uploader v-model="companyBrief.ssc_image" type="company-brief"/>
                        <VValidate 
                          name="Image" 
                          v-model="companyBrief.ssc_image"
                          :rules="companyBriefValidation.ssc_image" 
                        />
                      </div>
                      <div class="form-group">
                        <label class="control-label">Alt Image (ID)<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" placeholder="Alt Image" v-model="companyBrief.ssc_alt_id" @keyup="removeWildChar" @keydown="removeWildChar">
                        <VValidate 
                          name="Alt Image ID" 
                          v-model="companyBrief.ssc_alt_id"
                          :rules="{...companyBriefValidation.ssc_alt_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                        />
                      </div>
                      <div class="form-group">
                        <label class="control-label">Alt Image (EN)<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" placeholder="Alt Image" v-model="companyBrief.ssc_alt_en" @keyup="removeWildChar" @keydown="removeWildChar">
                        <VValidate 
                          name="Alt Image EN" 
                          v-model="companyBrief.ssc_alt_en"
                          :rules="{...companyBriefValidation.ssc_alt_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                        />
                      </div>
                    </div>
                  </div>
                  <template #footer>
                    <div class="text-right">
                      <button type="submit" class="btn btn-info btn-rounded">Save Changes</button>
                    </div>
                  </template>
                </b-card>
              </b-form>
            </validation-observer>
            <div class="row" v-else>
              <div class="col-md-9">
                <div v-html="companyBrief.ssc_desc_id"></div>
              </div>
              <div class="col-md-3">
                <b-img fluid-grow :src="uploader(companyBrief.ssc_image,'600')" />
              </div>
            </div>
          </b-card>
          <b-card>
            <template #header>
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <h5 class="card-title">Products</h5>
                  <small>Last Updated: {{udpateProducts | moment("DD MMMM YYYY, HH:mm")}} {{ udpateProducts ? 'WIB' : ''}}</small>
                </div>
                <router-link :to="{name: 'BoProducts'}">
                  <b-button class="btn-rounded" variant="info">
                    Product List
                    <i class="fa fa-arrow-circle-right ml-2"></i>
                  </b-button>
                </router-link>
              </div>
            </template>
            <b-form-checkbox v-if="moduleRole('update')" :checked="showProducts == 'Y' ? true : false" @change="changeSection(showProducts,'switch-home-products')">Show Section?</b-form-checkbox>
          </b-card>
          <b-card no-body>
            <template #header>
              <h5 class="card-title">Company Commitment</h5>
              <small>Last Updated: {{companyCommitment.ssc_updated_at | moment("DD MMMM YYYY, HH:mm")}} {{ companyCommitment.ssc_updated_at ? 'WIB' : ''}}</small>
            </template>
            <validation-observer
              v-slot="{ handleSubmit }" ref="VFormCommitment"
            >
            <b-form @submit.prevent="handleSubmit(submitCommitment)">
              <b-card-body>
                <b-form-checkbox v-if="moduleRole('update')" :checked="showCompanyCommitment == 'Y' ? true : false" @change="changeSection(showCompanyCommitment,'switch-home-company-commitment')">Show Section?</b-form-checkbox>
                <hr />
                <b-row>
                  <b-col md="6">
                    <b-form-group label-for="commitmentId">
                      <template #label>Content (ID) <strong class="text-danger">*</strong></template>
                      <CKEditor 
                        id="commitmentID"
                        :value.sync="companyCommitment.ssc_desc_id"
                        :customToolbar="configCKEditor"                        
                      />
                      <VValidate 
                        name="Commitment Description ID" 
                        v-model="companyCommitment.ssc_desc_id"
                        :rules="companyCommitmentValidation.ssc_desc_id" 
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label-for="commitmentEn">
                      <template #label>Content (EN) <strong class="text-danger">*</strong></template>        
                      <CKEditor 
                        id="commitmentEN"
                        :value.sync="companyCommitment.ssc_desc_en"
                        :customToolbar="configCKEditor"                      
                      />
                      <VValidate 
                        name="Commitment Description EN" 
                        v-model="companyCommitment.ssc_desc_en"
                        :rules="companyCommitmentValidation.ssc_desc_en" 
                      />
                    
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>
              <b-card-footer>
                <div class="text-right">
                  <b-button
                    type="submit"
                    variant="info"
                    class="btn-rounded"
                  >Save Changes</b-button>
                </div>
              </b-card-footer>
            </b-form>
            </validation-observer>
          </b-card>
          <b-card no-body>
            <template #header>
              <h5 class="card-title">Why Choose Us</h5>
              <small>Last Updated: {{ whyChoseUs.ssc_updated_at | moment("DD MMMM YYYY, HH:mm")}} {{ whyChoseUs.ssc_updated_at ? 'WIB' : ''}}</small>
            </template>

            <validation-observer
              v-slot="{ handleSubmit }" ref="VFormWCU"
            >
            <b-form @submit.prevent="handleSubmit(submitWCUStatic)">
              <b-card-body>
                <b-alert v-if="whyChoseUs['needSubmit']" variant="warning" show>
                  <b>{{ Config.wording.detectUpdate }}</b>
                </b-alert>
                <div class="row">
                  <div class="col-md-6 col-lg-5">
                    <div class="form-group">
                      <label for="whyDescription">Description (ID) <strong class="text-danger">*</strong></label>
                      <textarea id="whyDescription" v-model="whyChoseUs.ssc_desc_id" rows="4" class="form-control" @input="whyChoseUs['needSubmit'] = true" @keyup="removeWildChar" @keydown="removeWildChar"/>
                      <VValidate 
                        name="Description ID" 
                        v-model="whyChoseUs.ssc_desc_id"
                        :rules="{...whyChoseUsValidation.ssc_desc_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                      />
                    </div>
                    <div class="form-group">
                      <label for="whyDescriptionEn">Description (EN) <strong class="text-danger">*</strong></label>
                      <textarea id="whyDescriptionEn" v-model="whyChoseUs.ssc_desc_en" rows="4" class="form-control" @input="whyChoseUs['needSubmit'] = true" @keyup="removeWildChar" @keydown="removeWildChar"/>
                      <VValidate 
                        name="Description EN" 
                        v-model="whyChoseUs.ssc_desc_en"
                        :rules="{...whyChoseUsValidation.ssc_desc_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                      />
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-7">
                    <draggable tag="div" class="row gutter-2 draggable" v-model="WCUList" @start="startDragWCU" @end="endDragWCU">
                      <div class="col-md-6" v-for="(v, key) in WCUList" :key="key">
                        <div class="main-card benefit-card slide">
                          <div class="bullet-cta">
                            <b-button
                              variant="outline-warning"
                              class="btn-icon btn-rounded"
                              @click="whyModalOpen = true, setDataWhy(v)"
                              v-b-tooltip="'Edit Content'"
                            >
                              <i class="fas fa-pencil-alt"></i>
                            </b-button>
                          </div>
                          <div class="main-card-body">
                            <b-badge v-if="v.mwcu_is_active=='Y'" variant="success" :class="'post-block__badge'">Active</b-badge>
                            <b-badge v-else-if="v.mwcu_is_active=='N'" variant="danger" 
                            :class="'post-block__badge'">Not Active</b-badge>
                            <div class="benefit-card-media"><img :src="uploader(v.mwcu_logo)" alt="" class="benefit-card-media__img img-responsive"></div>
                            <h3 class="benefit-card__title">{{v.mwcu_title_id}}</h3>
                            <p class="benefit-card__desc">{{v.mwcu_desc_id}}</p>
                          </div>
                        </div>
                      </div>
                    </draggable>
                  </div>
                </div>
              </b-card-body>
              <b-card-footer>
                <div class="text-right">
                  <b-button variant="info" class="btn-rounded" type="submit">Save Changes</b-button>
                </div>
              </b-card-footer>
            </b-form>
            </validation-observer>
            
            <validation-observer
              v-slot="{ handleSubmit }" ref="VFormWhy"
            >
            <b-modal
              v-model="whyModalOpen"
              title="Update Why Choose Us"
              @ok.prevent="handleSubmit(submitWhy)"
              cancel-variant="secondary btn-rounded"
              ok-variant="primary btn-rounded"
              ok-title="Save Changes"
              no-close-on-backdrop
            >
              <b-form>          
                <div class="form-group">
                  <Uploader v-model="dataWhy.mwcu_logo" type="why-chose-us"/>    
                  <VValidate 
                    name="Logo" 
                    v-model="dataWhy.mwcu_logo"
                    :rules="whyChoseUsValidationList.mwcu_logo" 
                  />          
                </div>
                <div class="form-group">
                  <label for="">Title(ID) <strong class="text-danger">*</strong></label>
                  <input v-model="dataWhy.mwcu_title_id" type="text" class="form-control"  @input="whyChoseUs['needSubmit'] = true" @keyup="removeWildChar" @keydown="removeWildChar">
                  <VValidate 
                    name="title ID" 
                    v-model="dataWhy.mwcu_title_id"
                    :rules="{...whyChoseUsValidationList.mwcu_title_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                  />          
                </div>
                <div class="form-group">
                  <label for="">Description(ID) <strong class="text-danger">*</strong></label>
                  <textarea v-model="dataWhy.mwcu_desc_id" type="text" class="form-control" @input="whyChoseUs['needSubmit'] = true" @keyup="removeWildChar" @keydown="removeWildChar"/>
                  <VValidate 
                    name="Description ID" 
                    v-model="dataWhy.mwcu_desc_id"
                    :rules="{...whyChoseUsValidationList.mwcu_desc_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                  />     
                </div>
                <div class="form-group">
                  <label for="">Alt Image(ID) <strong class="text-danger">*</strong></label>
                   <input v-model="dataWhy.mwcu_alt_image_id" type="text" class="form-control" @input="whyChoseUs['needSubmit'] = true" @keyup="removeWildChar" @keydown="removeWildChar">
                  <VValidate 
                    name="Alt Image ID" 
                    v-model="dataWhy.mwcu_alt_image_id"
                    :rules="{...whyChoseUsValidationList.mwcu_alt_image_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                  />          
                </div>
                <div class="form-group">
                  <label for="">Link (ID)</label>
                   <input v-model="dataWhy.mwcu_link_id" type="text" class="form-control" @input="whyChoseUs['needSubmit'] = true" >
                  <VValidate 
                    name="Link ID" 
                    v-model="dataWhy.mwcu_link_id"
                    :rules="{regex:/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/}" 
                  />          
                </div>

                <div class="form-group">
                  <label for="">Title(EN) <strong class="text-danger">*</strong></label>
                  <input v-model="dataWhy.mwcu_title_en" type="text" class="form-control" @input="whyChoseUs['needSubmit'] = true" @keyup="removeWildChar" @keydown="removeWildChar">
                  <VValidate 
                    name="title EN" 
                    v-model="dataWhy.mwcu_title_en"
                    :rules="{...whyChoseUsValidationList.mwcu_title_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                  />          
                </div>
                <div class="form-group">
                  <label for="">Description(EN) <strong class="text-danger">*</strong></label>
                  <textarea v-model="dataWhy.mwcu_desc_en" type="text" class="form-control" @input="whyChoseUs['needSubmit'] = true" @keyup="removeWildChar" @keydown="removeWildChar"/>
                  <VValidate 
                    name="Description EN" 
                    v-model="dataWhy.mwcu_desc_en"
                    :rules="{...whyChoseUsValidationList.mwcu_desc_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                  />          
                </div>
                <div class="form-group">
                  <label for="">Alt Image(EN) <strong class="text-danger">*</strong></label>
                   <input v-model="dataWhy.mwcu_alt_image_en" type="text" class="form-control" @input="whyChoseUs['needSubmit'] = true" @keyup="removeWildChar" @keydown="removeWildChar">
                  <VValidate 
                    name="Alt Image EN" 
                    v-model="dataWhy.mwcu_alt_image_en"
                    :rules="{...whyChoseUsValidationList.mwcu_alt_image_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                  />          
                </div>
                <div class="form-group">
                  <label for="">Link (EN)</label>
                   <input v-model="dataWhy.mwcu_link_en" type="text" class="form-control" @input="whyChoseUs['needSubmit'] = true" >
                  <VValidate 
                    name="Link ID" 
                    v-model="dataWhy.mwcu_link_en"
                    :rules="{regex:/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/}" 
                  />          
                </div>
                <div class="form-group" v-if="(dataWhy.mwcu_link_id||'').length && (dataWhy.mwcu_link_en||'').length">
                  <b-form-checkbox
                    id="checkbox-1"
                    v-model="dataWhy.mwcu_is_blank"
                    name="checkbox-1"
                    value="Y"
                    unchecked-value="N"
                  >
                  open in new tab
                  </b-form-checkbox>
                </div>
                <b-form-group>                   
                <div class="row">
                  <div class="col-md-3">
                    <div class="custom-control custom-radio">
                      <input type="radio" id="customRadio1" v-model="dataWhy.mwcu_is_active" value="Y"
                        name="radioRow" class="custom-control-input">
                      <label class="custom-control-label" for="customRadio1">Active</label>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="custom-control custom-radio">
                      <input type="radio" id="customRadio3" v-model="dataWhy.mwcu_is_active" value="N"
                        name="radioRow" class="custom-control-input">
                      <label class="custom-control-label" for="customRadio3">Inactive</label>
                    </div>
                  </div>
                  <VValidate name="Status" v-model="dataWhy.mwcu_is_active"
                    :rules="'required'" />
                </div>
                </b-form-group>
                <!--for submit -->
                <b-button type="submit" class="d-none"></b-button>
              </b-form>
            </b-modal>
            </validation-observer>
          </b-card>
          <b-row>
            <b-col md="6">
              <b-card>
                <template #header>
                  <div class="d-flex align-items-center justify-content-between">
                    <div>
                      <h5 class="card-title">Malacca Care Center</h5>
                      <small>Last Updated: {{udpateMalaccaCenter | moment("DD MMMM YYYY, HH:mm")}} {{ udpateMalaccaCenter ? 'WIB' : ''}}</small>
                    </div>
                    <b-button variant="primary" class="btn-rounded" :to="{ name: 'BoContact', query: { openCareCenter: 'Y' } }">
                      Update Content
                      <i class="fas fa-arrow-circle-right ml-2"></i>
                    </b-button>
                  </div>
                </template>
                <b-form-checkbox v-if="moduleRole('update')" :checked="showMalaccaCenter == 'Y' ? true : false" @change="changeSection(showMalaccaCenter,'switch-home-malacca-center')">Show Section?</b-form-checkbox>
              </b-card>
            </b-col>
            <b-col md="6">
              <b-card>              
                <template #header>
                  <div class="d-flex align-items-center justify-content-between">
                    <div>
                      <h5 class="card-title">Latest News</h5>
                      <small>Last Updated: {{udpateLatestNews | moment("DD MMMM YYYY, HH:mm")}} {{ udpateLatestNews ? 'WIB' : ''}}</small>
                    </div>
                    <b-button class="btn-rounded" variant="info"
                      :to="{ name: 'ArticleList', query: {mainCategory : 'NEWS'} }"
                    >
                      View All <i class="fa fa-arrow-circle-right ml-2"></i>
                    </b-button>
                  </div>
                </template>
                <b-form-checkbox v-if="moduleRole('update')" :checked="showLatestNews == 'Y' ? true : false"  @change="changeSection(showLatestNews,'switch-home-latest-news')">Show Section?</b-form-checkbox>
                <b-table
                  responsive
                  :fields="blogFields"
                  :items="dataNews"
                  striped
                  bordered
                  class="mb-0 mt-2"
                  show-empty
                >
                  <template #cell(num)="data">{{ data.index += 1 }}</template>
                  <template #cell(title)="data">
                    <div class="wrap_title_post">
                      <h3>
                        <a href="javascript:;">{{ data.item.ma_title_id }}</a>
                      </h3>
                      <ul>
                        <li><i class="icon-user"></i> {{ data.item.ma_author }}</li>
                        <li><i class=" ti-calendar"></i> {{ data.item.ma_date | moment("DD MMMM YYYY, HH:mm") }}</li>
                        <li><i class="icon-eye"></i> {{ data.item.ma_viewer }}</li>
                      </ul>
                    </div>
                  </template>
                  <template #cell(category)="data">
                    <div><strong class="text-primary">{{ data.item.mac_category_id }}</strong></div>
                    <small>Article</small>
                  </template>
                </b-table>
              </b-card>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="SEO Settings" v-if="moduleRole('updateSEO')">
          <b-card no-body>
            <b-card-header>
              <b-card-title title-tag="h5">Home SEO Settings</b-card-title>
            </b-card-header>
            <validation-observer
              v-slot="{ handleSubmit }" ref="VFormSEO"
            >
            <b-form @submit.prevent="handleSubmit(submitSEO)">
              <b-card-body>
                <b-row>
                  <b-col lg="6">
                    <b-form-group label-for="homeMetaTitle">
                      <template #label>
                        Meta Title (ID)
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'Isi kolom ini untuk memudahkan mesin pencari memahami judul dari halaman website. Tips: Maksimal karakter yang disarankan untuk Meta Title adalah 60 karakter.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-input id="homeMetaTitle" placeholder="Meta Title" v-model="seoPageSetting.sss_meta_title_id"/>
                      <VValidate 
                        name="Meta Title ID" 
                        v-model="seoPageSetting.sss_meta_title_id"
                        :rules="{...seoValidation.sss_meta_title_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                      />   
                    </b-form-group>
                  </b-col>

                  <b-col lg="6">
                    <b-form-group label-for="homeMetaTitle">
                      <template #label>
                        Meta Title (EN)
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'This field functions to ease Search Engines understand the title of this website page. Pro tip: Make sure the characters length is less than 60 chars.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-input id="homeMetaTitle" placeholder="Meta Title" v-model="seoPageSetting.sss_meta_title_en"/>
                      <VValidate 
                        name="Meta Title EN" 
                        v-model="seoPageSetting.sss_meta_title_en"
                        :rules="{...seoValidation.sss_meta_title_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                      />   
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col lg="6">
                    <b-form-group label-for="homeMetaDesc">
                      <template #label>
                        Meta Description (ID)
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'Isi kolom ini sebagai deskripsi singkat dari isi halaman website. Tips: Maksimal karakter yang disarankan untuk Meta Description adalah 120-155 karakter.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-textarea id="homeMetaDesc" rows="5" placeholder="Meta Description" v-model="seoPageSetting.sss_meta_description_id"/>
                      <VValidate 
                        name="Meta Description ID" 
                        v-model="seoPageSetting.sss_meta_description_id"
                        :rules="{...seoValidation.sss_meta_description_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                      /> 
                    </b-form-group>
                  </b-col>

                  <b-col lg="6">
                    <b-form-group label-for="homeMetaDesc">
                      <template #label>
                        Meta Description (EN)
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'This field acts as the summary for your website\'s page. A good practice is to keep it around 120-155 characters.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-textarea id="homeMetaDesc" rows="5" placeholder="Meta Description" v-model="seoPageSetting.sss_meta_description_en"/>
                      <VValidate 
                        name="Meta Description EN" 
                        v-model="seoPageSetting.sss_meta_description_en"
                        :rules="{...seoValidation.sss_meta_description_en,regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                      /> 
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col lg="6">
                    <b-form-group label-for="homeMetaTags">
                      <template #label>
                        Meta Keywords (ID)
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'Isi kolom ini untuk memudahkan mesin pencari memahami topik halaman website. Gunakan keyword yang relevan dan user friendly, maksimal 10 keyword.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-tags id="homeMetaTags" v-model="seoPageSetting.sss_meta_keyword_id" placeholder="Type and press enter ..." remove-on-delete
                      :tag-validator="validatorTags"
                        tag-variant="primary" tag-class="text-white"/>
                    </b-form-group>
                  </b-col>

                  <b-col lg="6">
                    <b-form-group label-for="homeMetaTags">
                      <template #label>
                        Meta Keywords (EN)
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'This field tell Search Engines what the topic of a page is. Use a relevant and short topic, and make sure to keep only up to 10 keywords.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-tags id="homeMetaTags" v-model="seoPageSetting.sss_meta_keyword_en" placeholder="Type and press enter ..." remove-on-delete
                      :tag-validator="validatorTags"
                        tag-variant="primary" tag-class="text-white"/>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>
              <b-card-footer>
                <div class="text-right">
                  <b-button class="btn-rounded" type="submit" variant="primary">Save Changes</b-button>
                </div>
              </b-card-footer>
            </b-form>
            </validation-observer>
          </b-card>
        </b-tab>
      </b-tabs>
    </section>
  </b-container>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import BoCard from '@/components/backend/BoCard.vue'
import BoCardImg from '@/components/backend/BoCardImg.vue'
import Gen from '@/libs/Gen'
import draggable from 'vuedraggable'
import CKEditor from '@/components/CKEditor'

let $ = global.jQuery
let _ = global._

export default {
  name: 'BoHome',
  extends: GlobalVue,
  components: {
    BoCard,
    BoCardImg,
    draggable,
    CKEditor
  },
  mounted() {
    this.apiGet()
  },
  data() {
    return {
      idKey:'ms_id',
      statusKey: 'ms_is_active',
      sliderCollapse: false,
      slideMode: 'Add',
      sliderData: [],
      slideRow: {},
      seoRow: {},
      autoPlay: {},
      slideId: undefined,
      whyModalOpen: false,
      enableAutoplay: false,
      
      companyBriefOpen: false,
      companyBrief: {},
      companyBriefValidation : {},

      companyCommitment : {},
      companyCommitmentValidation :{},

      whyChoseUs: {},
      whyChoseUsValidation: {},
      whyChoseUsValidationList: {},
      WCUList: [],
      seoPageSetting: {},
      seoValidation: {},
      dataWhy: {},
      dataNews : [],

      showCompanyBrief: "",
      showCompanyCommitment: "",
      showLatestNews: "",
      udpateLatestNews: "",
      showMalaccaCenter: "",
      udpateMalaccaCenter: "",
      showProducts: "",
      udpateProducts:"",
      blogFields: [
				{ key: 'num', label: '#' },
				{ key: 'title', sortable: true },
				'category',
			],
      configCKEditor: [
            'heading',
            '|',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'link',
            'bulletedList',
            'numberedList',
            'blockQuote']
    }
  },
  computed: {
    parent(){
      return this.$parent.$parent.$parent
    }
  },
  methods: {
    closeFormCompany(){
      this.companyBriefOpen = false
      this.apiGet()
    },
    showUpdateCompanyBrief(){
      this.companyBriefOpen = true

      this.$refs.companyBriefForm.scrollIntoView()
    },
    addSlide() {
      this.slideMode = 'Add'
      this.sliderCollapse = true
      this.slideRow = {
        ms_button : 'N',
        ms_is_active: 'Y'
      }
      this.slideId = undefined
    },
    isHaveButton(data){

      setTimeout(()=>{
        if(data == 'Y') this.slideRow.ms_button = 'N'
        else this.slideRow.ms_button = 'Y'  
      },100)
    },
    updateSlide(k) {
      this.slideMode = 'Update'
      this.slideRow = this.sliderData[k]
      if(this.slideId == k){
        this.sliderCollapse = !this.sliderCollapse
      }else {
        this.sliderCollapse = true
      }
      this.slideId = k
    },
    changeAutoPlay(ap){
      if(ap == 'Y') this.autoPlay.isSlide = 'N'
      else if(ap == 'N') this.autoPlay.isSlide = 'Y'      
      
      this.updateAutoplay('status',this.autoPlay.isSlide)
    },
    changeDelay(e){
      this.updateAutoplay('delay',e)
    },
    startDrag(){
      $(".sortable-fallback").removeAttr("style")
      $(".sortable-fallback").css("display","none")
    },
    endDrag() {
      Gen.apiRest(
        "/do/" + this.modulePage, {
          data: {
            type: 'sort',
            data: this.sliderData
          }
        },
        "POST"
      )
    },


    startDragWCU(){
      $(".sortable-fallback").removeAttr("style")
      $(".sortable-fallback").css("display","none")
    },
    endDragWCU() {
      this.$set(this.whyChoseUs, 'needSubmit', true)
      Gen.apiRest(
        "/do/" + this.modulePage, {
          data: {
            type: 'sortItemWCU',
            data: this.WCUList
          }
        },
        "POST"
      )
    },
    setDataWhy(data){
      this.dataWhy = data
    },
    
    submitWhy(){
      this.doSubmit(
        "/do/" + this.modulePage,
        _.assign({
          type: 'updateWhyChoseUs'
        }, this.dataWhy),
        (type, resp) => {
          if (type == 'success') {
            this.loadingOverlay = true
            this.whyModalOpen = false
            this.apiGet()
            window.scrollTo(0, 0)
          }else{
            if(resp.response.status==422) {
              if((_.values(resp.response.data)[0][0]||"").indexOf('validation')>-1){
                var msg = ""
                $.each(Object.keys(resp.response.data), (i, v) => {
                  msg = msg + " <br/>" + v + " : " + _.values(resp.response.data[v])
                })
                return this.$swal.fire("Blocked With Validation!", msg)
              } else {
                return this.$swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
              }
            } else if (resp.response.status == 400) {
              return this.$swal.fire({
                title: resp.response.data.message,
                icon: 'error',
                confirmButtonText: 'Ok'
              })
            }
          }
        },
        'POST', 'VFormWhy'
      )
    },

    updateAutoplay(type, val) {
      if(type === 'status'){
        Gen.apiRest(
          "/do/BoHome",
          {
            data: {
              type: "updateAutoPlay",
              status: val
            }
          },
          "POST"
        )
      } else if(type === 'delay'){
        Gen.apiRest(
          "/do/BoHome",
          {
            data: {
              type: "updateAutoPlay",
              delay: val
            }
          },
          "POST"
        )
      }
    },

    submitSlider(id, IdFormSlider = 'VForm') {
      this.doSubmit(
        "/do/" + this.modulePage,
        _.assign({
          type: this.slideMode == 'Add' ? 'addSlider' : 'updateSlider'
        }, this.slideRow),
        (type, resp) => {
          if (type == 'success') {
            this.loadingOverlay = true
            this.slideRow = {
              ms_button : 'N'
            }
            this.sliderCollapse = false
            this.slideId = undefined

            this.apiGet()
            window.scrollTo(0, 0)
          }else{
            if(resp.response.status==422) {
              if((_.values(resp.response.data)[0][0]||"").indexOf('validation')>-1){
                var msg = ""
                $.each(Object.keys(resp.response.data), (i, v) => {
                  msg = msg + " <br/>" + v + " : " + _.values(resp.response.data[v])
                })
                return this.$swal.fire("Blocked With Validation!", msg)
              } else {
                return this.$swal.fire("Sorry", _.values(resp.response.data)[0][0])
              }
            } else if (resp.response.status == 400) {
              return this.$swal.fire({
                title:  resp.response.data.message,
                icon: 'error',
                confirmButtonText: 'Ok'
              })
            }
          }
        },
        'POST', IdFormSlider
      )

    },

    submitCompanyBrief(IdFormContent = 'VFormContent'){
      this.doSubmit(
        "/do/" + this.modulePage,
        _.assign({
          type: 'updateCompanyBrief'
        }, this.companyBrief),
        (type, resp) => {
          if (type == 'success') {
            this.loadingOverlay = true  
            this.apiGet()
          }else{
            if(resp.response.status==422) {
              if((_.values(resp.response.data)[0][0]||"").indexOf('validation')>-1){
                var msg = ""
                $.each(Object.keys(resp.response.data), (i, v) => {
                  msg = msg + " <br/>" + v + " : " + _.values(resp.response.data[v])
                })
                return this.$swal.fire("Blocked With Validation!", msg)
              } else {
                return this.$swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
              }
            } else if (resp.response.status == 400) {
              return this.$swal.fire({
                title: resp.response.data.message,
                icon: 'error',
                confirmButtonText: 'Ok'
              })
            }
          }
        },
        'POST', IdFormContent
      )
      this.companyBriefOpen = false
    },

    submitCommitment(IdForm = 'VFormCommitment'){
      this.doSubmit(
        "/do/" + this.modulePage,
        _.assign({
          type: 'updateCommitment'
        }, this.companyCommitment),
        (type, resp) => {
          if (type == 'success') {
            this.loadingOverlay = true  
            this.apiGet()
          }else{
            if(resp.response.status==422) {
              if((_.values(resp.response.data)[0][0]||"").indexOf('validation')>-1){
                var msg = ""
                $.each(Object.keys(resp.response.data), (i, v) => {
                  msg = msg + " <br/>" + v + " : " + _.values(resp.response.data[v])
                })
                return this.$swal.fire("Blocked With Validation!", msg)
              } else {
                return this.$swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
              }
            } else if (resp.response.status == 400) {
              return this.$swal.fire({
                title: resp.response.data.message,
                icon: 'error',
                confirmButtonText: 'Ok'
              })
            }
          }
        },
        'POST', IdForm
      )
    },
    inputNum(e){
      let regex = /^[0-9'+']*$/
      if (!regex.test(e.target.value) && e.target.value.length < 13) {
        return e.target.value = e.target.value.slice(0, -1)
      }
    },
    submitWCUStatic(){
      this.doSubmit(
        "/do/" + this.modulePage,
        _.assign({
          type: 'updateWhyChoseUsStatic'
        }, this.whyChoseUs),
        (type, resp) => {
          if (type == 'success') {
            this.whyChoseUs['needSubmit'] = false
            this.loadingOverlay = true  
            this.apiGet()
          }else{
            if(resp.response.status==422) {
              if((_.values(resp.response.data)[0][0]||"").indexOf('validation')>-1){
                var msg = ""
                $.each(Object.keys(resp.response.data), (i, v) => {
                  msg = msg + " <br/>" + v + " : " + _.values(resp.response.data[v])
                })
                return this.$swal.fire("Blocked With Validation!", msg)
              } else {
                return this.$swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
              }
            } else if (resp.response.status == 400) {
              return this.$swal.fire({
                title: resp.response.data.message,
                icon: 'error',
                confirmButtonText: 'Ok'
              })
            }
          }
        },
        'POST', 'VFormWCU'
      )
    },

    submitSEO(){
      this.doSubmit(
        "/do/" + this.modulePage,
        _.assign({
          type: 'updateSeo'
        }, this.seoPageSetting),
        (type, resp) => {
          if (type == 'success') {
            this.loadingOverlay = true  
            this.apiGet()
          }else{
            if(resp.response.status==422) {
              if((_.values(resp.response.data)[0][0]||"").indexOf('validation')>-1){
                var msg = ""
                $.each(Object.keys(resp.response.data), (i, v) => {
                  msg = msg + " <br/>" + v + " : " + _.values(resp.response.data[v])
                })
                return this.$swal.fire("Blocked With Validation!", msg)
              } else {
                return this.$swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
              }
            } else if (resp.response.status == 400) {
              return this.$swal.fire({
                title: resp.response.data.message,
                icon: 'error',
                confirmButtonText: 'Ok'
              })
            }
          }
        },
        'POST', 'VFormSEO'
      )
    },
    changeSection(data,slug){
      Gen.apiRest(
        "/do/BoHome",
        {
          data: {
            type: "updateSection",
            slug: slug,
            data: data
          }
        },
        "POST"
      ).then(resp=>{
        this.doSetAlertForm(resp.data)
        this.apiGet()
      })
    },
    
        
    cancelSlider() {
      this.slideRow = {}
      this.slideId = undefined
      this.sliderCollapse = false
    },
    
    autoFill(to = "", to2 = "", from = ""){
      this.slideRow[to] = this.slideRow[from]
      this.slideRow[to2] = this.slideRow[from]    
    },
  },
  watch:{
    $route(){
      this.apiGet()
    },
    'seoPageSetting.sss_meta_title_id'(v){
      let el = document.getElementById('Meta Title ID')
      if(el){
        let showcount = document.getElementById('Meta Title ID' + 'count')
        let cErr = document.getElementById('Meta Title ID' + 'count-err')
        if(v.length >= 3 && v.length < 60){
          el.style.display = "none"
          showcount.innerHTML = 60 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"
          showcount.style.display = "none"        
        }
      }
    },
    'seoPageSetting.sss_meta_title_en'(v){
      let el = document.getElementById('Meta Title EN')
      if(el){
        let showcount = document.getElementById('Meta Title EN' + 'count')
        let cErr = document.getElementById('Meta Title EN' + 'count-err')
        if(v.length >= 3 && v.length < 60){
          showcount.innerHTML = 60 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"          
          showcount.style.display = "none"
        }
      }
    },
    'seoPageSetting.sss_meta_description_id'(v){
      let el = document.getElementById('Meta Description ID')
      if(el){
        let showcount = document.getElementById('Meta Description ID' + 'count')
        let cErr = document.getElementById('Meta Description ID' + 'count-err')
        if(v.length >= 3 && v.length < 160){
          showcount.innerHTML = 160 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"
          showcount.style.display = "none"
        }
      }
    },
    'seoPageSetting.sss_meta_description_en'(v){
      let el = document.getElementById('Meta Description EN')
      if(el){
        let showcount = document.getElementById('Meta Description EN' + 'count')
        let cErr = document.getElementById('Meta Description EN' + 'count-err')
        if(v.length >= 3 && v.length < 160){
          showcount.innerHTML = 160 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"
          showcount.style.display = "none"
        }
      }
    },
  }
}
</script>